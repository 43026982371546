import React from "react"
import { graphql } from "gatsby"

import FourchetteIcon from "../components/svg/fourchette-icon"
import IconComponent from "../components/icon"
import LocalizedLink from "../components/localizedLink"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { IntersectionObserver } from "../components/utils/intersection-observer"
import { FadeInUp } from "../components/animations/fadeInUp"

class AcceleratorPage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.accelerator

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO title="Accélérateur" />

          <IntersectionObserver className="u-pd-vt-xl u-fadeInUp">

            <div className="l-container u-pd-hz-l">
              <FadeInUp yOffset={50} delay={100}>
                <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-l">
                  <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                  {content.title}
                </h1>
              </FadeInUp>

              <FadeInUp yOffset={50} delay={200}>
                {content.desc.map((item, index) => {
                  return (
                    <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"program-desc--" + index}>{item}</p>
                  )
                })}
              </FadeInUp>

            </div>

            <FadeInUp yOffset={100} delay={300} className="l-container-md">
              <div className="l-grid u-center u-pd-vt-xl">
                {content.keypoints.list.map((item, index) => {
                  return (
                    <div className="l-col-12 l-col-4@main" key={"keypoint-" + index}>
                      <div className={"u-bold u-pd-bottom-l u-pd-l@main u-height-full u-bordered@main"} style={index > 0 ? { borderLeft: "none"} : {}}>
                        <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-hz-auto" style={{ height: "83px", width: "83px"}}>
                          <IconComponent name={item.icon} />
                        </div>
                        <div className={"u-" + item.color}>{item.number}</div>
                        <div className={"u-mg-hz-auto u-" + item.color} style={{ maxWidth: "250px" }}>{item.desc}</div>
                      </div>
                    </div>
                  )
                })}
                <div className="l-col-12 u-pd-m">
                  <p className="u-center u-fs-xs">
                    {content.keypoints.source.desc} <a href={content.keypoints.source.link} target="_blank" rel="noopener noreferrer" className="u-underline">{content.keypoints.source.label}</a>
                  </p>
                </div>
              </div>
            </FadeInUp>

          </IntersectionObserver>

          <section className="u-bg-white">
              <div className="l-container u-border-top u-pd-vt-xxl u-pd-hz-l u-pd-hz-0@main">
                  <div className="l-grid">
                    <div className="l-col-12 l-col-6@main">
                      <h2 className="c-h2 u-red u-mg-bottom-l">{content.sections[0].title}</h2>
                    </div>
                    <div className="l-col-12 l-col-6@main u-pd-left-xl@main">
                      {content.sections[0].desc.map((item, index) => {
                        return (
                          <p className="u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"sponsors-desc--" + index}>{item}</p>
                        )
                      })}
                    </div>
                  </div>
              </div>
          </section>

          <section className="u-bg-white">
              <div className="l-container u-border-top u-pd-vt-xxl u-pd-hz-l u-pd-hz-0@main">
                  <div className="l-grid">
                    <div className="l-col-12 l-col-6@main">
                      <h2 className="c-h2 u-yellow u-mg-bottom-l">{content.sections[1].title}</h2>
                    </div>
                    <div className="l-col-12 l-col-6@main u-pd-left-xl@main">
                      {content.sections[1].desc.map((item, index) => {
                        return (
                          <p className="u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"sponsors-desc--" + index}>{item}</p>
                        )
                      })}
                    </div>
                  </div>
              </div>
          </section>

          <section className="">
            <div className="l-container u-border-top u-pd-vt-xxl">
              {/* <h2 className="c-h2 u-uppercase u-center u-green80 u-mg-bottom-m">{content.about.title}</h2> */}

              { content.about.list.map((item, index) => {
                return (
                  <div className={"l-grid u-mg-bottom-l " + (index%2 == 0 ? '' : 'u-flex-dir-row-reverse')} key={"about-brand-" + index}>
                    <div className="l-col-12 l-col-7@main u-pd-m u-pd-bottom-l">
                      <h3 className="c-h2 u-green u-mg-bottom-m" dangerouslySetInnerHTML={{__html: item.title}}></h3>
                      {item.desc.map((item, jndex) => {
                        return (
                          <p className="u-mg-bottom-m" key={"about-desc--" + jndex} dangerouslySetInnerHTML={{__html: item}}></p>
                        )
                      })}
                    </div>
                    <div className="l-col-12 l-col-5@main u-pd-l">
                      <div className="u-bordered u-pd-l">
                        {item.quote.text.map((item, jndex) => {
                          return (
                            <p className="u-italic u-mg-bottom-m" key={"about-desc--" + jndex} dangerouslySetInnerHTML={{__html: item}}></p>
                          )
                        })}
                        <div className="u-bold u-right">{item.quote.author}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>

          <section className="">
            <div className="l-container u-border-top">
              <div className="l-grid u-flex-center-vt">
                <div className="l-col-12 l-col-6@main u-center u-pd-xl">
                  <div className="u-fs-m u-bold u-pd-bottom-l u-mg-hz-auto" style={{ maxWidth: "300px" }}>{content.bands[0].punchline}</div>
                  <LocalizedLink to={'/thematiques'} className="c-btn c-btn--red" style={{ minWidth: "260px" }}>
                    <span>{content.bands[0].cta}</span>
                    <span>{content.bands[0].cta_hover}</span>
                  </LocalizedLink>
                </div>
                <div className="l-col-12 l-col-6@main u-center u-pd-xl">
                  <div className="u-fs-m u-bold u-pd-bottom-l u-mg-hz-auto" style={{ maxWidth: "300px" }}>{content.bands[1].punchline}</div>
                  <LocalizedLink to={'/programme'} className="c-btn c-btn--green" style={{ minWidth: "260px" }}>
                    <span>{content.bands[1].cta}</span>
                    <span>{content.bands[1].cta_hover}</span>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </section>

        </div>
      </Layout>
    )
  }
}

export default AcceleratorPage

export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        accelerator {
          bands {
            cta
            cta_hover
            punchline
          }
          desc
          keypoints {
            list {
              desc
              icon
              number
              color
            }
            source {
              desc
              label
              link
            }
          }
          about {
            title
            list {
              title
              desc
              quote {
                text
                author
              }
            }
          }
          sections {
            desc
            title
          }
          title
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
